<template>
	<div style="padding: 24rem;box-sizing: border-box;">
		<el-form ref="form" :model="form" label-position="left" label-width="100rem">
			<el-form-item label="组合名称" prop="name">
				<span style="font-weight: normal;font-size: 16rem;color: #1E3AA2;">
					{{ form.name }}
				</span>
			</el-form-item>
			<el-form-item label="总金额">
					<span style="font-weight: normal;font-size: 16rem;color: #1E3AA2;">
					{{ form.price }}
				</span>
			</el-form-item>

			<el-form-item v-for="(i,index) in form.fees" :key="index" label-width="0">
				<div style="width: 100%;height: 88rem;background: #F6F9FF;border-radius: 4rem;display: flex;align-items: center;padding: 0 92rem 0 24rem">
					<el-form-item label="科目类型" label-width="104rem">
						<div style="width: 200rem">{{ i.type }}</div>
					</el-form-item>
					<el-form-item label="科目名称" label-width="104rem" style="margin: 0 80rem">
						<div style="width: 200rem">{{ i.name }}</div>
					</el-form-item>
					<el-form-item label="金额" label-width="72rem">
						<div>{{ i.price }}</div>
					</el-form-item>

				</div>
			</el-form-item>
		</el-form>
		<div>
			<el-button @click="$router.back()">返回</el-button>
		</div>
	</div>
</template>

<script>

export default {
	_config: {"route": {"path": "look", "meta": {"title": "查看"}}},
	data() {
		return {
			form: {
				name: null,
				price: null,
				fees: [{
					name: null,
					type: null,
					price: null,
				}],
			},
		};
	},
	mounted() {
		this.$_register.get("api/finance/combination/show?id=" + this.$route.query.id).then(res => {
			this.form = res.data.data;
		});
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label {
	font-size: 16rem !important;
}

div {
	font-size: 16rem !important;
}

</style>